<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    style="overflow: visible;"
    :class="[
      {
        expanded: isExpanded(),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
  >
    <button @click="toggleCollapsed" class="toggle-btn d-none d-xl-block"  @mouseenter="updateMouseHovered(false)"
       :class="[{
        no_touch_toggle: true,
       }]"
       style="z-index: 12000;margin-top:14px;  outline: none; position: absolute; cursor: pointer; height: 30px; width: 30px;  display: flex; align-items: center; justify-content: center; height: 30px; border-radius:30px; border:none;  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;" >
          <feather-icon v-if="(!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered))"
            icon='ChevronLeftIcon' size="23" style="cursor: pointer"/>
          <feather-icon v-else
          icon='ChevronRightIcon' size="23" style="cursor: pointer"/>
    </button>
    <!-- main menu header-->
    <div class="navbar-header"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
    style="overflow: hidden; max-width: 100%;">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <h1 class="mt-3 " v-if="isExpanded()">
          {{ companyName }}
        </h1>
        <h1 class="mt-3" v-if="!isExpanded()">
          {{ companyName.substring(0, 1) }}
        </h1>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <vertical-nav-menu-items
        :items="navItems"
        class="navigation navigation-main"
        :updateMouseHovered="updateMouseHovered"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <div class="navbar-bottom-header tw-w-full">
      <div class="d-flex justify-content-center align-items-end mb-1">
        <span class="powered-by mr-1 tw-pb-2" v-if="isExpanded()">
          {{ $t('Powered by') }}
        </span>
        <b-link
          class="navbar-brand"
          :class="[{
            logo_expanded: !isExpanded(),
          }]"
          to="/"
        >
          <Logo
            width="68"
            height="28"
          />
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { provide, computed, ref } from 'vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { mapState, mapGetters } from 'vuex'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import Logo from '@/components/shared/Logo.vue'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    Logo,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { isMouseHovered, isVerticalMenuCollapsed, collapseTogglerIcon, toggleCollapsed, updateMouseHovered } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 50,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    ...mapState('user', ['user', 'shortcuts', 'categorizedDashboardLists']),
    ...mapGetters('user', ['customEntities', 'visibleShortcuts']),
    companyName() {
      return this.$store.state.user.user?.companyName
    },
    navItems() {
      const originalNavItems = navMenuItems
      const settingsOnly = item => item.itemGroup === 'settings'
      const searchOnly = item => item.itemGroup === 'advanced-search'
      const shortcuts = item => item.itemGroup === 'shortcuts'
      const customEntityHeader = []
      if (this.customEntities.length) {
        customEntityHeader.push({
          header: 'Custom Entities',
          permissions: this.customEntities.map(item => ({
            resource: item.key,
            action: 'Read',
          })),
        })
      }

      if (this.categorizedDashboardLists.length) {
        const dashboardIndex = originalNavItems.findIndex(nav => nav.title === 'Dashboard')
        const categorizedDashboard = []
        const noCategoryDashboard = []
        this.categorizedDashboardLists.forEach(
          e => {
            if (e?.categoryName) {
              const categoryIndex = categorizedDashboard.findIndex(e1 => e1.title === e.categoryName)
              if (categoryIndex < 0) {
                const categoryObject = {
                  action: 'Read',
                  children: [],
                  icon: e.categoryIcon,
                  route: 'dashboard',
                  standard: true,
                  title: e.categoryName,
                }
                if (e?.name && e.name.length > 0) {
                  categoryObject.children.push(e)
                }
                categorizedDashboard.push(categoryObject)
              } else {
                categorizedDashboard[categoryIndex].children.push(e)
              }
            } else {
              noCategoryDashboard.push(e)
            }
          },
        )
        if (categorizedDashboard.length > 0) {
          originalNavItems[dashboardIndex].children = categorizedDashboard
          originalNavItems[dashboardIndex].children.push(...noCategoryDashboard)
        } else {
          originalNavItems[dashboardIndex].children = noCategoryDashboard
        }
      } else {
        const dashboardIndex = originalNavItems.findIndex(nav => nav.title === 'Dashboard')

        originalNavItems.splice(dashboardIndex, 1)
      }

      if (this.shortcuts.length) {
        const shortcutsIndex = originalNavItems.findIndex(nav => nav.title === 'Shortcut links')

        originalNavItems[shortcutsIndex].children = this.visibleShortcuts
      }
      return [
        ...originalNavItems.filter(item => item.standard),
        ...customEntityHeader,
        ...this.customEntities.map(item => ({
          title: item.label,
          href: `/custom-entity/${item.key}`,
          resource: item.key,
          action: 'Read',
        })),
        ...originalNavItems.filter(item => searchOnly(item)),

        ...(this.shortcuts.length ? originalNavItems.filter(item => shortcuts(item)) : ''),
        ...originalNavItems.filter(item => settingsOnly(item)),
      ]
    },
  },
  methods: {
    isExpanded() {
      return !this.isVerticalMenuCollapsed || (this.isVerticalMenuCollapsed && this.isMouseHovered)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
.toggle-btn {
  background-color: white;
  transition: 0.2s;
  margin-left: calc(100% - 10px);
  padding:0px 2px 0px 2px;
}
.toggle-btn:hover {
  background-color: rgb(248, 248, 248);
}
.powered-by {
  color: var(--colour--menu-text-color);
}
.logo_expanded{
  margin-right: 0px;
}
.no_touch_toggle{
  // pointer-events: none;
}
</style>
