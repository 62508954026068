/**
 * Changes made to support multi-permissions
 *
 * You can now add optional "permissions" array field and if one of those permissions are true, it will show the menu
 *
 * else you can use vuexy's transitional "resource" and "action" permission
 *
 */

import { GenericTabs, URL_USER_GUIDES, Entity, Schema } from '@/constants/app'

const advancedSearchPermissions = [
  {
    resource: 'Personal_Query',
    action: 'Read',
  },
  {
    resource: 'Global_Query',
    action: 'Read',
  },
]

const salesAndCommissionsPermissions = [
  {
    resource: 'Sales',
    action: 'Read',
  },
  {
    resource: 'Commissions',
    action: 'Read',
  },
]

const documentPermissions = [
  {
    resource: 'Document',
    action: 'Read',
  },
  {
    resource: 'Document',
    action: 'General_Read',
  },
]

export const systemSettingsTabs = [
  {
    title: 'Company Settings',
    icon: 'SettingsIcon',
    routeName: GenericTabs.COMPANY_SETTINGS,
    resource: 'Settings',
    // resource: can('Read', 'Settings'),
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    routeName: GenericTabs.USER_SETTINGS,
  },
  {
    title: 'Groups',
    icon: 'UsersIcon',
    routeName: GenericTabs.GROUP_SETTINGS,
  },
  {
    title: 'Roles and Permissions',
    icon: 'LockIcon',
    routeName: GenericTabs.ROLE_SETTINGS,
  },
  {
    title: 'Digital Signature',
    icon: 'EditIcon',
    routeName: GenericTabs.DIGITAL_SIGNATURE_SETTINGS,
  },
  {
    title: 'Custom Entities',
    icon: 'SidebarIcon',
    routeName: GenericTabs.CUSTOM_ENTITY_SETTINGS,
    resource: 'Custom_Entity',
  },
  {
    title: 'Field Management',
    icon: 'EditIcon',
    routeName: GenericTabs.FIELD_MANAGEMENT,
    initialTab: `${Schema.CRM}.${Entity.ACTIVITIES}`,
    resource: 'Field_Management',
  },
  {
    title: 'Inquiries',
    icon: 'HelpCircleIcon',
    routeName: GenericTabs.INQUIRY_SETTINGS,
  },
  {
    title: 'To Do',
    icon: 'ListIcon',
    routeName: GenericTabs.TODO_SETTINGS,
  },
  {
    title: 'Statuses',
    icon: 'ActivityIcon',
    routeName: GenericTabs.STATUS_SETTINGS,
  },
  {
    title: 'Priorities',
    icon: 'ChevronsUpIcon',
    routeName: GenericTabs.PRIORITY_SETTINGS,
  },
  {
    title: 'Categories',
    icon: 'GridIcon',
    routeName: GenericTabs.CATEGORY_SETTINGS,
  },
  {
    title: 'Sources',
    icon: 'DatabaseIcon',
    routeName: GenericTabs.SOURCE_SETTINGS,
  },

  {
    title: 'Integrations',
    icon: 'GitMergeIcon',
    routeName: GenericTabs.INTEGRATIONS_SETTINGS,
  },
  {
    title: 'Dashboard and Widgets',
    icon: 'CrosshairIcon',
    routeName: GenericTabs.DASHBOARD_WIDGET_SETTINGS,
    initialTab: 'dashboards',
    resource: 'Dashboard',
  },
  {
    title: '360 Overview',
    icon: 'GridIcon',
    routeName: GenericTabs.EXPANDED_ENTITY_OVERVIEW,
    initialTab: `${Schema.CRM}.${Entity.ACTIVITIES}`,
    resource: 'Expanded_Entity',
  },
  {
    title: 'CRM Relationships',
    icon: 'GitPullRequestIcon',
    routeName: GenericTabs.CRM_RELATIONSHIP_SETTINGS,
    initialTab: Entity.COMPANY,
    resource: 'CRM',
  },

  {
    title: 'Shortcuts',
    icon: 'ExternalLinkIcon',
    routeName: GenericTabs.SHORTCUTS_SETTINGS,
    resource: 'System_Settings',
  },
  {
    title: 'Commission Models',
    icon: 'AwardIcon',
    routeName: GenericTabs.COMMISSION_MODELS,
    resource: 'System_Settings',
  },
  {
    title: 'Model',
    name: 'model',
    icon: 'AwardIcon',
    routeName: GenericTabs.MODEL,
    resource: 'System_Settings',
  },
]

export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    action: 'Read',
    standard: true,
  },
  {
    title: 'Companies',
    route: 'companies',
    icon: 'BriefcaseIcon',
    action: 'Read',
    resource: 'CRM',
    standard: true,
  },
  {
    title: 'Contacts',
    route: 'contacts',
    icon: 'UsersIcon',
    action: 'Read',
    resource: 'CRM',
    standard: true,
  },
  {
    title: 'Documents',
    route: 'documents',
    icon: 'CheckSquareIcon',
    permissions: documentPermissions,
    resource: 'Document',
    standard: true,
  },
  {
    title: 'Notes',
    route: 'notes',
    icon: 'ClipboardIcon',
    action: 'Read',
    resource: 'Note',
    standard: true,
  },
  {
    title: 'Inquiries',
    route: 'inquiries',
    icon: 'HelpCircleIcon',
    action: 'Read',
    resource: 'Inquiry',
    standard: true,
  },
  {
    title: 'To Do',
    route: 'todo',
    icon: 'ListIcon',
    action: 'Read',
    resource: 'Todo',
    standard: true,
  },
  {
    title: 'Reports',
    route: 'reports.index',
    icon: 'FileTextIcon',
    action: 'Read',
    resource: 'Report',
    standard: true,
  },
  {
    title: 'Sales & Commissions',
    itemGroup: 'sales-and-commissions',
    permissions: salesAndCommissionsPermissions,
    icon: 'AwardIcon',
    standard: true,
    children: [
      {
        title: 'Sales',
        name: 'sales',
        route: GenericTabs.SALES,
        uuidKey: 'sales',
        parent: 'sales-and-commissions',
        href: '/sales-and-commissions/sales',
      },
      {
        title: 'Commissions',
        uuidKey: 'commissions',
        name: 'commissions',
        parent: 'sales-and-commissions',
        route: GenericTabs.COMMISSIONS,
        href: '/sales-and-commissions/commissions',
      },
      {
        title: 'Contacts',
        uuidKey: 'sales-contacts',
        name: 'sales-contacts',
        parent: 'sales-and-commissions',
        route: GenericTabs.SALES_CONTACTS,
        href: '/sales-and-commissions/contacts',
      },
      {
        title: 'Seller',
        uuidKey: 'seller',
        name: 'seller',
        parent: 'sales-and-commissions',
        route: GenericTabs.SELLER,
        href: '/sales-and-commissions/sales/:id?/:tableName?/seller/:sellerId',
      },
      {
        title: 'Handler',
        uuidKey: 'handler',
        name: 'handler',
        parent: 'sales-and-commissions',
        route: GenericTabs.SELLER,
        href: '/sales-and-commissions/handler/:id',
      },
    ],
  },
  {
    header: 'Search',
    itemGroup: 'advanced-search',
    permissions: advancedSearchPermissions,
  },
  {
    title: 'Advanced Search',
    itemGroup: 'advanced-search',
    route: 'advanced-search',
    icon: 'SearchIcon',
    permissions: advancedSearchPermissions,
    standard: false,
  },

  {
    header: 'Shortcuts',
    itemGroup: 'shortcuts',
    action: 'Read',
    resource: 'System_Settings',
  },

  {
    title: 'Shortcut links',
    itemGroup: 'shortcuts',
    icon: 'ExternalLinkIcon',
    children: [],
    action: 'Read',
    resource: 'System_Settings',
    standard: false,
  },
  {
    header: 'Settings',
    itemGroup: 'settings',
    action: 'Read',
    resource: 'System_Settings',
  },
  {
    title: 'System Settings',
    itemGroup: 'settings',
    icon: 'SettingsIcon',
    route: GenericTabs.COMPANY_SETTINGS,
    action: 'Read',
    resource: 'System_Settings',
    standard: false,
  },
  {
    title: 'Help',
    itemGroup: 'settings',
    icon: 'HelpCircleIcon',
    externalLink: URL_USER_GUIDES,
    action: 'Read',
    resource: 'AppUserGuide',
  },
  {
    title: 'Forms',
    itemGroup: 'settings',
    route: 'forms',
    icon: 'FilePlusIcon',
    action: 'Read',
    resource: 'Form',
    standard: false,
  },
  {
    title: 'Document Templates',
    itemGroup: 'settings',
    route: 'document-template',
    icon: 'FileIcon',
    action: 'Read',
    resource: 'DocumentTemplate',
    standard: false,
  },
]
