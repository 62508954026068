var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    {
      expanded: _vm.isExpanded(),
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
  ],staticStyle:{"overflow":"visible"}},[_c('button',{staticClass:"toggle-btn d-none d-xl-block",class:[{
      no_touch_toggle: true,
     }],staticStyle:{"z-index":"12000","margin-top":"14px","outline":"none","position":"absolute","cursor":"pointer","height":"30px","width":"30px","display":"flex","align-items":"center","justify-content":"center","border-radius":"30px","border":"none","box-shadow":"rgba(0, 0, 0, 0.3) 0px 1px 4px"},on:{"click":_vm.toggleCollapsed,"mouseenter":function($event){return _vm.updateMouseHovered(false)}}},[((!_vm.isVerticalMenuCollapsed || (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered)))?_c('feather-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"ChevronLeftIcon","size":"23"}}):_c('feather-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"ChevronRightIcon","size":"23"}})],1),_c('div',{staticClass:"navbar-header",staticStyle:{"overflow":"hidden","max-width":"100%"},on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_vm._t("header",function(){return [(_vm.isExpanded())?_c('h1',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.companyName)+" ")]):_vm._e(),(!_vm.isExpanded())?_c('h1',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.companyName.substring(0, 1))+" ")]):_vm._e()]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":evt => {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0
      }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.navItems,"updateMouseHovered":_vm.updateMouseHovered}})],1),_c('div',{staticClass:"navbar-bottom-header tw-w-full"},[_c('div',{staticClass:"d-flex justify-content-center align-items-end mb-1"},[(_vm.isExpanded())?_c('span',{staticClass:"powered-by mr-1 tw-pb-2"},[_vm._v(" "+_vm._s(_vm.$t('Powered by'))+" ")]):_vm._e(),_c('b-link',{staticClass:"navbar-brand",class:[{
          logo_expanded: !_vm.isExpanded(),
        }],attrs:{"to":"/"}},[_c('Logo',{attrs:{"width":"68","height":"28"}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }