<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <span class="ml-50 text-body tw-flex tw-items-center">
        <img
          class="tw-h-3 tw-mr-2"
          :src="currentLocale.flag"
          :alt="`${currentLocale.name} Flag`"
        >
        {{ currentLocale.name }}
      </span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setLocale(localeObj.locale)"
    >
      <span class="tw-flex tw-items-center">
        <img
          class="tw-h-3 tw-mr-2"
          :src="localeObj.flag"
          :alt="`${localeObj.name} Flag`"
        >
        <span class="ml-50">{{ localeObj.name }}</span>
      </span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'
import dayjs from 'dayjs'
import 'dayjs/locale/nb'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BImg,
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        name: 'English',
        flag: require('@/assets/images/flags/flag--en.svg'),
      },
      {
        locale: 'no',
        name: 'Norwegian',
        flag: require('@/assets/images/flags/flag--no.svg'),
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },

  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
      if (this.$i18n.locale === 'no') dayjs.locale('nb')
      else { dayjs.locale('en') }
      // TODO: Call User's Default Language API
    },
  },
}
</script>

<style></style>
