<template>
  <b-nav-item-dropdown
    id="dropdown-notification"
    ref="dropdownNotification"
    right
    class="dropdown-notification tw-mx-3"
  >
    <template #button-content>
      <div class="bell-icon-container">
        <feather-icon
          id="bell-icon"
          class="bell-icon-container__icon"
          size="20"
          icon="BellIcon"
        />
        <b-badge
          v-if="unreadNotificationCount"
          class="bell-icon-container__badge"
          pill
          variant="danger"
        >
          {{ unreadNotificationCount }}
        </b-badge>
      </div>
    </template>

    <div class="notification-container2">
      <div class="notification-container2__header row-flex-justify-space-between row-flex-align-baseline">
        <h3>{{ $t('Notifications') }}</h3>
        <div
          v-if="unreadNotificationCount"
          class="row-flex-align-center"
        >
          <span
            class="text-body tw-text-3xs tw-mr-2 cursor-pointer"
            @click="markAllAsRead"
          >
            {{ $t('Mark all as read') }}
          </span>
          <b-badge
            pill
            variant="primary"
          >
            {{ unreadNotificationCount }} {{ $t('New') }}
          </b-badge>
        </div>
      </div>

      <div
        v-infinite-scroll="loadMore"
        class="notification-container2__body"
        infinite-scroll-disabled="loading"
        infinite-scroll-distance="0"
        :infinite-scroll-immediate-check="false"
      >
        <b-row
          v-for="(notification, index) in notifications"
          :key="index"
          :class="{
            'notification-container2__body__content--disable': !notification.entityUuidKey || !$can('Read', permissionName(notification)),
            'notification-container2__body__content--enable': notification.entityUuidKey,
          }"
          align-v="center"
          class="notification-container2__body__content"
        >
          <b-col
            cols="9"
            class="notification-container2__body__content__title"
            @click="redirectToNotifyPage(notification, index)"
          >
            <span class="title tw-text-xs">{{ notification.subject }}</span>
            <span class="subtitle tw-text-2xs">{{ notification.message }}</span>
          </b-col>

          <b-col
            cols="2"
            offset="1"
            class="notification-container2__body__content--status"
          >
            <div
              v-if="!notification.read"
              class="mark-as-read-container"
              @click="markAsRead($event, notification.uuidKey, index)"
            >
              <span
                :id="`unread-${index}`"
                class="unread-badge"
              />

              <div class="mark-as-read-container--text">
                {{ $t('Mark as read') }}
              </div>
            </div>
          </b-col>

          <!-- <b-tooltip
            v-if="!notification.read"
            :target="`unread-${index}`"
            placement="right"
          >
            {{ $t('Mark as read') }}
          </b-tooltip> -->
        </b-row>
      </div>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import notificationProfile from '@/services/notificationProfile'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import { GenericTabs } from '@/constants/app'
import { title } from '@/@core/utils/filter'
import { getNotificationRoute } from '@/helpers/notificationRouteMap'

export default {
  components: { FeatherIcon },
  data() {
    return {
      loading: false,
      notifications: [],
      page: 0,
      totalItems: 1,
      size: 15,
      title,
      show: false,
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', {
      unreadNotificationCount: 'unreadNotificationCountDisplay',
    }),
  },

  created() {
    this.fetchNotificationsData()
  },
  mounted() {
    if (this.user) {
      this.getUnreadNotificationsCount()
    }
  },
  methods: {
    ...mapActions({
      getUnreadNotificationsCount: 'user/getUnreadNotificationsCount',
    }),

    async markAllAsRead(e) {
      e.stopPropagation()
      await this.$async(notificationProfile.markAllAsRead())
      this.reFetchData()
      this.getUnreadNotificationsCount()
    },

    reFetchData() {
      this.page = 0
      this.totalItems = 1
      this.notifications = []
      this.fetchNotificationsData()
    },
    async fetchNotificationsData() {
      if (this.totalItems > this.notifications.length) {
        const query = {
          page: this.page,
          size: this.size,
        }
        const { response } = await this.$async(notificationProfile.fetchNotificationLists(query))
        const data = response.data
        const items = data.pageItems.map(e => {
          const routeTarget = e.subject.match(/.*?-->(.+)<--$/)
          if (routeTarget) {
            return {
              ...e,
              route: routeTarget[1],
              subject: routeTarget[0].replace(/-->.+<--$/, ''),
            }
          }
          return { ...e, route: null }
        })
        this.notifications = [...this.notifications, ...items]
        this.totalItems = data.filteredItemsCount
        this.page++
      }
    },
    async loadMore() {
      this.loading = true

      this.fetchNotificationsData()
      this.loading = false
    },

    async markAsRead(e, id, index) {
      e.stopPropagation()
      this.notifications[index].read = true
      await this.$async(notificationProfile.markAsRead(id))
      await this.getUnreadNotificationsCount()
    },

    permissionName(item) {
      const entityName = item.tableKey === 'company' || item.tableKey === 'contact' ? 'CRM' : this.title(item.tableKey)
      return item.schemaKey === 'custom_schema' ? item.tableKey : entityName
    },

    redirectToNotifyPage(data, index) {
      if (data.route) {
        const route = getNotificationRoute(data.route)
        this.$router.push(route)
      } else {
        if (!data.entityUuidKey) return false
        if (!this.$can('Read', this.permissionName(data))) return false

        let tab
        const params = {
          id: data.entityUuidKey,
          tab,
        }
        if (data.schemaKey === 'custom_schema') {
          params.key = data.tableKey
          params.tab = GenericTabs.OVERVIEW_360
        }
        params.tab = data.tableKey === 'document' ? GenericTabs.PREVIEW : GenericTabs.OVERVIEW_360
        const name = data.schemaKey === 'custom_schema' ? 'custom-entity-data-view' : `${data.tableKey}-view`

        this.$router.push({
          name,
          params,
        })
      }

      this.$refs.dropdownNotification.hide()
      this.notifications[index].read = true
      this.$async(notificationProfile.markAsRead(data.uuidKey))
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';
#dropdown-notification {
  .bell-icon-container {
    position: relative;
    &__badge {
      position: absolute;
      right: -6px;
      top: -8px;
    }
  }
  .dropdown-menu {
    width: 380px !important;

    .notification-container2 {
      min-height: 200px;
      width: 100%;
      padding: 10px 2px 10px 0px;
      &__header,
      &__body {
        padding-left: 15px;
      }
      &__header {
        padding: 10px;
      }

      &__body {
        width: 100%;
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 10px auto;
        &__content {
          &:not(:last-child) {
            border-bottom: 1px solid $colour--table-row-divider;
          }

          &--enable {
            &:hover {
              background-color: darken($colour--dropdown-bg, 10%) !important;
            }
          }
          cursor: pointer;

          padding: 5px 0;
          &__title {
            color: var(--colour--body);
            display: flex;
            flex-direction: column;
            .subtitle {
              color: var(--colour--menu-group-text-color);
            }
          }
          &--status {
            .mark-as-read-container {
              min-height: 50px;
              height: 100%;
              display: flex;
              position: relative;
              white-space: nowrap;
              height: 100%;
              &:hover {
                > .unread-badge {
                  left: -15px;
                  transition: ease-in-out 0.1s;
                  opacity: 0;
                }

                > .mark-as-read-container--text {
                  right: 3px;
                  transition: ease-in-out 0.2s;
                  opacity: 1;
                }
              }
              &--text {
                text-align: center;
                line-height: 15px;
                padding: 0 5px;
                border-radius: 5px;
                right: -55px;
                opacity: 0;
                color: var(--colour--primary);
                font-weight: 700;
                height: 15px;
              }

              &--text,
              .unread-badge {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                transition: ease-in-out 0.3s;
              }

              .unread-badge {
                opacity: 1;
                cursor: pointer;
                display: flex;
                background: var(--colour--primary);
                height: 10px;
                width: 10px;
                left: 10px;
                // top: 20%;
                border-radius: 50%;
              }
            }
          }

          &--disable {
            .notification-container2__body__content__title {
              color: $colour--gray !important;
            }
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  #dropdown-notification {
    .dropdown-menu {
      width: 100% !important;
    }

    .mark-as-read-container--text {
      background-color: var(--colour--white);
    }
  }
}
</style>
