<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ $t('COPYRIGHT') }} © {{ new Date().getFullYear() }}
      <span class="d-none d-sm-inline-block">Enably, {{ $t('All rights Reserved') }}</span>
    </span>
  </p>
</template>

<script>
export default {
  components: {},
}
</script>
