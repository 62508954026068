<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle || pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="col-12 breadcrumbs-top">
        <div class='back-btn-dvi' style="margin-top: -2px; margin-right: 6px;">
          <button class="back-btn" @click="backButtonPressed"  :disabled="!canGoBack()"
            style="height: 30px; width: 30px;outline: none;border: none;">
            <feather-icon class="back-btn-icon" icon='ChevronLeftIcon' size="23" />
          </button>
        </div>
        <h2 class="content-header-title float-left pr-1 mb-0">
          <b-spinner
            v-if="textLoading(mainTitle)"
            variant="primary"
          />
          <template v-else>
            <span> {{ mainTitle }} </span>
          </template>
        </h2>
        <div class="breadcrumb-wrapper justfy-content-center align-items-center">
          <b-breadcrumb>
            <b-breadcrumb-item>
              <div class="d-flex" @click="handleHomeClick()">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                  style="margin-right: 4px"
                />
                <p style="margin:0px">Home</p>
              </div>
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="goToSourceBreadcrumb()"  v-if="sourceBreadcrumb" :active="false">
                <p style="margin:0px">...</p>
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="goToBaseTab()"  v-if="hasBaseTab()" :active="false">
                <p style="margin:0px">{{ this.$route.meta.breadcrumb[0].baseTab.text ? this.$route.meta.breadcrumb[0].baseTab.text : this.customBasetab.text }}</p>
            </b-breadcrumb-item>
            <!-- <b-breadcrumb-item
              v-for="item in breadcrumbs"
              :key="item.text"
              :active="item.active"
              style="margin-top: 2px; cursor: pointer"
              @click="handleClickBreadcrumbItem(item)"
            >
              <b-spinner
                v-if="textLoading(item.text)"
                variant="primary"
                small
              />
              <template v-else>
                {{ $t(item.text) }}
              </template>
            </b-breadcrumb-item> -->
            <div id="info-instr" style="padding: 2px 0px 0px 0px;margin-left: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" style="fill:#40C057; margin-top: -3px;"> <path d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M12,17L12,17c-0.552,0-1-0.448-1-1v-4 c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v4C13,16.552,12.552,17,12,17z M12.5,9h-1C11.224,9,11,8.776,11,8.5v-1 C11,7.224,11.224,7,11.5,7h1C12.776,7,13,7.224,13,7.5v1C13,8.776,12.776,9,12.5,9z"></path></svg>
            </div>

          <b-tooltip target="info-instr">
            {{ $t('Use the breadcrumb items to easily navigate through the application.') }}
          </b-tooltip>
          </b-breadcrumb>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BRow, BCol } from 'bootstrap-vue'
import { TEXT_ELLIPSIS, GenericTabs } from '@/constants/app'
import Ripple from 'vue-ripple-directive'
import RootEvents from '@/constants/rootEvents'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  data() {
    return {
      pageTitle: null,
      breadcrumbs: [],
      customBasetab: null,
      sourceBreadcrumb: null,
      GenericTabs,
    }
  },
  computed: {
    mainTitle() {
      return this.pageTitle || this.$t(this.$route.meta.pageTitle)
    },

    appTitle() {
      if (this.textLoading(this.mainTitle)) {
        return TEXT_ELLIPSIS
      }

      const mainTitle = this.mainTitle !== this.$t(this.breadcrumbs[0]?.text) ? `${this.mainTitle} | ` : ''

      const appTitle = `${mainTitle}${this.breadcrumbs?.map(b => this.$t(b.text))?.join(' > ')}`

      let tab = this.$route.params.tab
      if (tab) {
        tab = tab === this.$t(tab) ? this.$case.title(tab) : this.$t(tab)
        return `${appTitle} > ${tab}`
      }

      return appTitle
    },
  },
  watch: {
    appTitle(value) {
      window.document.title = value
    },
    $route: {
      handler(newRoute, oldRoute) {
        if (oldRoute == null && newRoute.meta.breadcrumb.length > 0) {
          this.breadcrumbs.push(newRoute.meta.breadcrumb[0])
        }
        if (!this.$route.name.includes('-view')) {
          this.pageTitle = null
        }
        if (newRoute.name === 'custom-entity-data-view') {
          this.customBasetab = {
            text: this.$case.title(oldRoute.params.tab),
            path: `/custom-entity/${newRoute.params.key}`,
          }
        }
        if (this.sourceBreadcrumb != null) {
          this.sourceBreadcrumb = null
          this.customBasetab = null
        }

        if (newRoute.meta.breadcrumb[0].sourceViews != null && newRoute.meta.breadcrumb[0].sourceViews.includes(oldRoute.name)) {
          this.sourceBreadcrumb = {
              path: oldRoute.path,
          }
        }

        // if()

        if (!oldRoute || !['custom-entity-data-view', oldRoute.name].includes(newRoute.name)) {
         /**
         * This resets the `breadcrumbs` if:
         * • `oldRoute` is `undefined` (if browser just loaded)
         * • `$route.name` !== `custom-entity-data-view`
         * • `newRoute.name` !== `oldRoute.name`
         */
          this.breadcrumbs = this.$lodash.cloneDeep(newRoute.meta.breadcrumb)
        }
      },

      immediate: true,
    },
  },
  mounted() {
    this.$root.$on(RootEvents.SET_PAGE_TITLE, value => {
      this.pageTitle = value
    })

    this.registerBusEvent(RootEvents.UPDATE_BREADCRUMB_ITEM, ({ index = 0, value }) => {
      if (this.breadcrumbs[index]) {
        this.breadcrumbs[index] = value
      }
    })
  },
  destroyed() {
    this.$root.$off(RootEvents.SET_PAGE_TITLE)
    this.$root.$off(RootEvents.UPDATE_BREADCRUMB_ITEM)
  },
  methods: {
    hasBaseTab() {
      return this.$route.meta.breadcrumb[0].baseTab != null
    },
    goToBaseTab() {
      let path = this.$route.meta.breadcrumb[0].baseTab.path
      if (path === '/custom-entity' && this.customBasetab != null) {
        path = this.customBasetab.path
      }
      this.$router.push(path)
    },

    goToSourceBreadcrumb() {
      this.$router.push(this.sourceBreadcrumb.path)
      this.sourceBreadcrumb = null
    },
    handle1BackClick() {
      if (this.canGoBack()) {
        this.$router.go(-1)
      }
    },
    canGoBack() {
      return window.history.length - this.$root.historyCount > 0
    },
    backButtonPressed() {
      if (this.canGoBack()) {
        this.$router.go(-1)
      }
    },
    handleHomeClick() {
      this.$router.push('/')
    },
    handleClickBreadcrumbItem(item) {
      if (item.name && item.name === 'sales') {
        this.$router.push({
          name: item.name,
          params: {
            id: this.$route.params.id,
            tableName: this.$route.params.tableName,
          },
        })
      } else if (item.text === 'Document Template') {
        this.$router.push({ name: 'document-template' })
      } else {
        this.$router.push(item.to)
      }
    },
    textLoading(text) {
      return text === TEXT_ELLIPSIS
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
.back-btn {
transition: 0.3s;
border-radius: 4px;
}
.back-btn:hover{
  background-color: rgb(191, 226, 207);
}
.back-btn:hover .back-btn-icon{
//  color: white;
}
</style>
