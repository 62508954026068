<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <div class="global-search__wrapper position-absolute align-self-start">
      <GlobalSearchInput />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto nav-profile">
      <locale />
      <NotificationNavBar ref="notificationNav" />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div
            v-if="user"
            class="d-sm-flex d-none user-nav"
          >
            <p class="user-name font-weight-bolder mb-0">
              {{ fullName }}
            </p>
            <span class="user-status">{{ user.jobTitle }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :src="require('@/assets/images/avatars/dummy-avatar.jpg')"
            class="badge-minimal"
          />
        </template>

        <!-- <b-dropdown-divider /> -->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push('/profile/notifications')"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>{{ $t('Account Settings') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import * as Stomp from '@stomp/stompjs'
import GlobalToastNotification from '@/components/shared/GlobalToastNotification.vue'
import GlobalSearchInput from '@/components/shared/GlobalSearchInput.vue'
import NotificationNavBar from '@/components/shared/NotificationNavbar.vue'
import SockJs from 'sockjs-client'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    GlobalSearchInput,
    Locale,
    NotificationNavBar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      webSocketConnection: null,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['fullName']),
    frontendUrl() {
      const { protocol, host } = window.location
      return `${protocol}//${host}/login`
    },
    logoutUrl() {
      return `${this.$http.defaults.baseURL}/logout`
    },
  },

  watch: {
    async isOnline() {
      if (this.user) {
        this.initiateWebSocket()
      }
    },
  },

  mounted() {
    const refreshTime = parseInt(localStorage.getItem('token_expiration') || 0) * 1000
    // Reinitiatewebsocket every refreshtime mins to refresh token even user are in idle

    if (refreshTime) {
      setInterval(() => {
        this.initiateWebSocket()
      }, refreshTime)
    }
  },

  methods: {
    ...mapActions({
      getUnreadNotificationsCount: 'user/getUnreadNotificationsCount',
    }),
    async initiateWebSocket() {
      await this.getUnreadNotificationsCount()
      // START: disconnect first if connection already exist (cause duplicate calling of toast)
      if (this.webSocketConnection) this.webSocketConnection.deactivate()

      // END: disconnect first if connection already exist (cause duplicate calling of toast)
      const baseUrl = process.env.VUE_APP_PROZESS_API_ENDPOINT

      const stompConfig = new Stomp.Client({
        webSocketFactory: () => new SockJs(`${baseUrl}/api/websocket/?token=${localStorage.getItem('access_token')}`),
        beforeConnect: event => {
          this.getUnreadNotificationsCount()
        },
        reconnectDelay: 10000,
        onConnect: frame => {
          // START: Subscribing to Global notification
          this.webSocketConnection.subscribe('/queue/notification-new', notification => {
            const notificationParse = JSON.parse(notification.body)
            this.callToast(notificationParse)
            this.getUnreadNotificationsCount()
          })

          // END: Subscribing to Global notification

          // START: Subscribing to User notification
          this.webSocketConnection.subscribe('/user/queue/notification-new', userNotif => {
            const userNotification = JSON.parse(userNotif.body)
            this.callToast(userNotification)
            this.getUnreadNotificationsCount()
          })
        },
      })

      this.webSocketConnection = new Stomp.Client(stompConfig)
      this.webSocketConnection.activate()
    },
    async logout() {
      // Reset ability
      // this.$ability.update(initialAbility)
      try {
        await useJwt.logout()
        this.clearData()
      } catch (err) {
        this.clearData()
        this.$router.push('/login')
      }
    },
    clearData() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('token_expiration')
    },

    callToast(message) {
      this.$refs.notificationNav.reFetchData()
      const notificationContent = {
        component: GlobalToastNotification,
        props: {
          message,
          myRouter: this.$router,
        },
      }
      this.$toast(notificationContent, {
        closeButton: false,
        timeout: 7000,
        containerClassName: 'toast-notification',
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/master-variables.scss';
.Vue-Toastification__toast {
  padding: 0 !important;
}
.toast-notification {
  min-height: 200px;
  min-width: 500px;
  &__content {
    width: 100%;
    height: 100%;
    &__header {
      background-color: var(--colour--primary) !important;
    }
    &__body {
      background-color: var(--colour--white) !important;
      padding: 0 !important;
      .notification__body {
        padding: 0 !important;
        .notification__body__content {
          padding: 0 !important;
          .notification__body__content__title {
            padding: 0 !important;
            margin: 0 !important;
            font-size: 1.5rem !important;
            font-weight: 600 !important;
            color: var(--colour--primary) !important;
          }
          .notification__body__content__description {
            padding: 0 !important;
            margin: 0 !important;
            font-size: 1.2rem !important;
            font-weight: 400 !important;
            color: var(--colour--primary) !important;
          }
        }
      }
    }
  }
}

.nav-profile {
  .dropdown-menu {
    min-width: 160px !important;
    width: 100% !important;
  }
}

.global-search__wrapper {
  top: 50%;
  transform: translateY(-16px);
  left: 64px;

  @include xl {
    left: 16px;
  }
}
</style>
