import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { systemSettingsTabs } from '@/navigation/vertical'

const { t } = useI18nUtils()
const { canViewVerticalNavMenuHeader } = useAclUtils()

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    const span = h('span', {}, t(this.item.header))
    const icon = h('feather-icon', { props: { icon: 'MoreHorizontalIcon', size: '18' } })
    if (canViewVerticalNavMenuHeader(this.item)
      || (
        this.$optional(this.item, 'permissions', []).length
        && this.item.permissions.some(permission => this.$can(permission.action, permission.resource))
      )
    ) {
      return h('li', { class: 'navigation-header text-truncate' }, [span, icon])
    }

    if (this.item.header === 'Settings') {
      const hasPermission = systemSettingsTabs.some(perm => this.$can('Read', perm.resource))

      if (hasPermission) return h('li', { class: 'navigation-header text-truncate' }, [span, icon])
    }
    return h()
  },
}
