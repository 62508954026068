import API from '@axios'
import { stringify } from 'query-string'

const API_URL = '/api/search/simple'

export default {
  search(query = {}) {
    return API.get(`${API_URL}?${stringify(query)}`)
  },
}
