<template>
  <li
    v-if="hasPermission(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }} </span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import { systemSettingsTabs } from '@/navigation/vertical'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    updateMouseHovered: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  methods: {
    hasPermission(item) {
      const hiddenRoutes = ['seller', 'handler', 'sales-contacts', 'model']

      if (hiddenRoutes.includes(item.name)) return false
      if (item.parent === 'dashboard') return true
      if (item.title === 'System Settings') {
        if (!this.$can('Read', 'System_Settings')) return false
        const settingsTabs = systemSettingsTabs.filter(tab => tab.resource)
        return settingsTabs?.some(tab => this.$store.state.user.user.permissions.permissions.some(permission => permission.name === tab.resource))
      }
      if (this.$optional(item, 'permissions', []).length) {
        return item.permissions.some(permission => this.canViewVerticalNavMenuLink(permission))
      }
      return this.canViewVerticalNavMenuLink(item)
    },
  },
}
</script>
